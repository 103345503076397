import { render, staticRenderFns } from "./SelectEditor.vue?vue&type=template&id=08a8aee0&scoped=true&"
import script from "./SelectEditor.vue?vue&type=script&lang=js&"
export * from "./SelectEditor.vue?vue&type=script&lang=js&"
import style0 from "./SelectEditor.vue?vue&type=style&index=0&id=08a8aee0&scoped=true&lang=css&"
import style1 from "./SelectEditor.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a8aee0",
  null
  
)

export default component.exports