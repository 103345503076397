<template>
    <div class="ag-input-wrapper">
        <b-form-input class="w-100" v-model="value" ref="input"/>
    </div>
</template>

<script>
    import { BFormInput } from 'bootstrap-vue'
    import {nextTick} from '@vue/composition-api'
    export default {
        components: {BFormInput},
        data() {
            return {
                value: null
            }
        },
        methods: {
            getValue() {
                return this.value
            }
        },
        mounted() {
            this.value = this.params.value
            nextTick(() => this.$refs.input.focus())
        }
    }
</script>

<style scoped>
.button {
  padding: 0.4rem;
}
</style>