<template>
    <div class="h-100 d-flex align-items-center">
        <b-form-input class="w-100 h-100" type="password" ref="input" v-model="value"/>
    </div>
</template>
<script>
    const {nextTick} = require('@vue/composition-api')
    import { BFormInput } from 'bootstrap-vue'

    export default {
        components: {BFormInput},
        data() {
            return {
                value: ''
            }
        },
        methods: {
            getValue() {
                return this.value
            },
            isCancelBeforeStart() {
                return false
            }
        },
        mounted() {
            nextTick(() => this.$refs.input.focus())
        }
    }

</script>