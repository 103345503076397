export const $itemColors = [
    [
        '#545454', '#130f40', '#222f3e', '#1e272e', '#383838',
        '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
        '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
        '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6',
        '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d',
        '#81ecec', '#a29bfe', '#55efc4', '#0984e3', '#e84393'
    ],
    [
        '#FFEEEE', '#FFDDDD', '#FFCCCC', '#FFBBBB', '#FFAAAA',
        '#FF9999', '#FF8888', '#FF7777', '#FF6666', '#FF5555', // Red
        '#FFE5CC', '#FFDCB3', '#FFD4A1', '#FFCB8F', '#FFC379',
        '#FFB966', '#FFB04D', '#FFA433', '#FF9B1A', '#FF9100', // Orange
        '#FFD9FF', '#FFCCCC', '#FFB3FF', '#FF99FF', '#FF80FF',
        '#FF66FF', '#FF4DFF', '#FF33FF', '#FF1AFF', '#FF00FF' // Pink
    ],
    [
        '#D9B3FF', '#CC99FF', '#BF80FF', '#B366FF', '#A64DFF',
        '#9933FF', '#8C1AFF', '#8000FF', '#7300E6', '#6600CC', // Purple
        '#CCCCFF', '#B8B8FF', '#A3A3FF', '#8F8FFF', '#7A7AFF',
        '#6666FF', '#5252FF', '#3D3DFF', '#2929FF', '#1414FF', // Blue
        '#CCFFCC', '#C2FFC2', '#B8FFB8', '#ADFFAD', '#A3FFA3',
        '#99FF99', '#8FFF8F', '#85FF85', '#7AFF7A', '#70FF70' // Green
    ],
    [
        '#E6CCFF', '#DAC1FF', '#CFB6FF', '#C4ABFF', '#B9A0FF',
        '#AE95FF', '#A38AFF', '#9880FF', '#8D75FF', '#826AFF', // Lavender
        '#CCD9FF', '#C2CEFF', '#B8C4FF', '#ADC9FF', '#A3BEFF',
        '#99B4FF', '#8FAAFF', '#85A0FF', '#7A96FF', '#708BFF', // Light blues
        '#F2F2F2', '#E6E6E6', '#D9D9D9', '#CCCCCC', '#BFBFBF',
        '#B3B3B3', '#A6A6A6', '#999999', '#8C8C8C', '#808080' // Grey
    ]
]
