<template>
    <div>
        {{ value }} %
    </div>
</template>

<script>
    export default {
        data() {
            return {
                value: null,
                array: []
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                if (!params || params.value === null || params.value === undefined) {
                    return 0
                }

                return Number(params.value) / 100
            }
        },
        mounted() {
            this.array = this.params.values
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>