<template>
    <div class="d-flex align-items-center px-1">
        <div @click="page(-1)" style="cursor: pointer">
            <feather-icon icon="ArrowLeftIcon" size="15" stroke-width="4"/>
        </div>
        <b-row cols="5" :style="color" style="margin: 10px; border: none;">
            <b-col style="padding: 5px; width: 10px;" v-for="(item, indexItem) in colors" :key="indexItem">
                <div @click="$emit('input', item)" :style="getStyle(item)" style="height: 20px; border-radius: 5px" />
            </b-col>
        </b-row>
        <div  @click="page(1)" style="cursor: pointer">
            <feather-icon icon="ArrowRightIcon" size="15" stroke-width="4"/>
        </div>
    </div>

</template>
<script>
    import {BRow, BCol} from 'bootstrap-vue'
    import {$itemColors} from '@/libs/itemColors'

    export default {
        components: {
            BRow,
            BCol
        },
        props:{
            value: {
                type: String
            }
        },
        computed: {
            colors() {
                return this.rows[this.currentPage]
            }
        },
        data() {
            return {
                rows: $itemColors,
                color: '',
                currentPage: 0
            }
        },
        methods: {
            page(number) {
                if (this.currentPage + number < 0) {
                    this.currentPage = this.rows.length - 1
                } else if (this.currentPage + number >= this.rows.length) {
                    this.currentPage = 0
                } else {
                    this.currentPage += number
                }

            },
            getStyle(color) {
                return `background-color: ${color}`
            }
        }
    }

</script>