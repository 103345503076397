<template>
    <div :class="{'text-danger': value < 0 && page == 'issued_bills'}">
        {{ valueToDisplay }} <span v-if="valueToDisplay !== '/'">€</span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                value: null,
                array: [],
                page: ''
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                if (!params || params.value === null || params.value === undefined) {
                    return 0
                }

                return this.$numberRounding(params.value, params.decimals ?? 2)
            }
        },
        computed: {
            valueToDisplay() {
                if (!this.params || this.params.value === null || this.params.value === undefined) {
                    return 0
                } else if (this.params.value === 0 && this.params.data.no_predefined_price === true) {
                    return '/'
                }

                return this.$numberRounding(this.params.value, this.params.decimals ?? 2)
            }
        },
        mounted() {
            this.array = this.params.values
            this.page = this.$route.name
        }
    }
</script>