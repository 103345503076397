<template>
    <div class="">
        <b-badge v-if="value" class="" variant="success">{{$t('general.yes')}}</b-badge>
        <b-badge v-else class="" :variant="color">{{$t('general.no')}}</b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    export default {
        components: {BBadge},
        data() {
            return {
                value: null
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                return params.valueFormatted ? params.valueFormatted : params.value
            }
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        },
        computed: {
            color() {
                if (this.$router.app._route.name === 'product_display_groups') {
                    return 'warning'
                }
                return 'danger'
            }
        }
    }
</script>

<style scoped>
</style>