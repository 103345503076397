<template>
    <div class="h-100 d-flex align-items-center justify-content-center p-1" @keyup="keyUp">
        <b-form-checkbox switch class="w-100 active-editor" ref="input" v-model="value" @change="inputChanged">{{$t('general.active')}}</b-form-checkbox>
    </div>
</template>
<script>
    const {nextTick} = require('@vue/composition-api')
    import { BFormCheckbox } from 'bootstrap-vue'

    export default {
        components: {BFormCheckbox},
        data() {
            return {
                value: null,
                isOpen: false
            }
        },
        methods: {
            getValue() {
                return this.value
            },
            isCancelBeforeStart() {
                return false
            },
            keyUp(e) {
                if (e.key === 'Enter')  {
                    this.value = !this.value
                    nextTick(() => this.params.stopEditing())
                }
            },
            inputChanged() {
                nextTick(() => this.params.stopEditing())
            }
        },
        mounted() {
            this.value = this.params.value
            this.isOpen = true
            nextTick(() => this.$refs.input.focus())
        }
    }
</script>
<style>
.active-editor label {
  margin: unset !important;
}
</style>