<template>
    <div>
        <b-badge v-if="value.event_type === EventType.Work" variant="success">{{getWorkInfo(value)}}</b-badge>
        <b-badge v-else-if="value.event_type === EventType.Lunch" variant="warning">{{getWorkInfo(value)}}</b-badge>
        <b-badge v-else-if="value.event_type === EventType.Stopped" variant="danger">{{getWorkInfo(value)}}</b-badge>
        <div v-else>/</div>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    import {EventType} from '@/libs/enums/EmployeeTimeEvent'
    export default {
        components: {BBadge},
        data() {
            return {
                value: null
            }
        },
        methods: {
            refresh(params) {
                this.value = params.data.last_time_event
            },
            getWorkInfo(value) {
                if (value) {
                    if (value.event_type === EventType.Work) return `${this.$t('general.time_event_work')} - ${this.eventTime(value.timestamp)}`
                    else if (value.event_type === EventType.Lunch) return `${this.$t('employee_time.time_event_lunch')} - ${this.eventTime(value.timestamp)}`
                    else if (value.event_type === EventType.Stopped) return `${this.$t('employee_time.time_event_stop')}`
                }
                return '/'
            },
            eventTime(timestamp) {
                if (!timestamp) return '---'

                const eventTimestamp = this.$dayjs(timestamp).unix()

                let secondsLeft = this.currentTimestamp - eventTimestamp
                const secondsInDay  = 60 * 60 * 24
                const secondsInHour = 60 * 60

                const days = Number(Math.floor(secondsLeft / secondsInDay).toFixed(0))
                secondsLeft = secondsLeft % secondsInDay

                const hours = Math.floor(secondsLeft / secondsInHour).toFixed(0)
                secondsLeft = secondsLeft % secondsInHour
              
                const minutes = Math.floor(secondsLeft / 60).toFixed(0)
                const seconds = (secondsLeft % 60).toFixed(0)

                let timeText = ''
                if (days === 1) timeText += `${days} ${this.$t('general.time_event_day')} `
                else if (days > 1) timeText += `${days} ${this.$t('general.time_event_days')} `

                timeText += `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
                return timeText
            }
        },
        computed: {
            EventType() {
                return EventType
            },
            currentTimestamp() {
                return this.$store.getters['timestamp/getCurrentTimestamp']
            }
        },
        beforeMount() {
            this.value = this.params.data.last_time_event
        }
    }
</script>

<style scoped>
</style>