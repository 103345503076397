<template>
    <div class="">
        <b-badge class="w-100" style="border: 1px solid #292929" :style="[{'background-color':value},{'color':value}]">{{value}}</b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    export default {
        components: {BBadge},
        data() {
            return {
                value: null
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                return params.valueFormatted ? params.valueFormatted : params.value
            }

        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>

<style scoped>
</style>