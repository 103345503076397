<template>
    <div style="height: 100%;">
        <ag-grid-vue
            ref="gridComponent"
            style="width: 100%; height: 100%;"
            :class="[themeSkin === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine']"
            id="myGrid"
            :columnDefs="columnDefs"
            @grid-ready="onGridReady"
            :suppressRowClickSelection="true"
            :defaultColDef="defaultColDef"
            :rowSelection="rowSelection"
            :gridOptions="gridOptions"
            :rowData="rowData"
            @cell-value-changed="onCellValueChanged"
            :frameworkComponents="frameworkComponents"
            :context="context"
            :getRowClass="(params) => getRowClass(params)"
            @grid-size-changed="resizeColumnsToFit"
            @selection-changed="selectionChanged"/>

        <div class="d-flex justify-content-between flex-wrap" v-if="rowData.length > 0">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                    <span v-if="rowData.length > 0 && pagination">
                        {{ ((pagination.currentPage -1) * pagination.perPage) + 1 }} -
                        <span v-if="pagination.currentPage * pagination.perPage < pagination.totalRows">
                            {{ pagination.currentPage * pagination.perPage}}
                        </span>
                        <span v-else>
                            {{pagination.totalRows}}
                        </span>
                        {{$t('pagination.of')}} {{ pagination.totalRows }}
                    </span>
                </span>

                <b-dropdown variant="none" no-caret>
                    <template v-slot:button-content>
                        <span>({{pagination.perPage}} {{$t('pagination.per_page')}}) </span>
                        <feather-icon icon="ChevronDownIcon" size="16" class="text-body align-middle mr-25"/>
                    </template>

                    <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                        {{ length }}
                    </b-dropdown-item>

                </b-dropdown>
            </div>
            <div v-if="pagination.perPage < pagination.totalRows">
                <b-pagination :value="1" :total-rows="pagination.totalRows" :per-page="pagination.perPage" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" v-model="pagination.currentPage">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {BDropdown, BDropdownItem, BPagination} from 'bootstrap-vue'
    import 'ag-grid-community/dist/styles/ag-grid.css'
    import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
    import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
    import {AgGridVue} from 'ag-grid-vue'
    import TextEditor from '@/views/components/Table/TextEditor.vue'
    import FloatingFilter from '@/views/components/Table/FloatingFilter.vue'
    import ColorEditor from '@/views/components/Table/ColorEditor.vue'
    import PriceAndPercentageEditor from '@/views/components/Table/PriceAndPercentageEditor.vue'
    import PackagingEditor from '@/views/components/Table/PackagingEditor.vue'
    import EmailEditor from '@/views/components/Table/EmailEditor.vue'
    import SelectEditor from '@/views/components/Table/SelectEditor.vue'
    import DateEditor from '@/views/components/Table/DateEditor.vue'
    import TableActions from '@/views/components/Table/TableActions.vue'
    import DisplayColor from '@/views/components/Table/DisplayColor.vue'
    import DisplayActive from '@/views/components/Table/DisplayActive.vue'
    import ActiveEditor from '@/views/components/Table/ActiveEditor.vue'
    import TextAreaEditor from '@/views/components/Table/TextAreaEditor.vue'
    import PasswordEdit from '@/views/components/Table/PasswordEdit.vue'
    import CustomTooltip from '@/views/components/Table/CustomTooltip.vue'
    import DisplayStatus from '@/views/components/Table/DisplayStatus.vue'
    import DisplayDifference from '@/views/components/Table/DisplayDifference.vue'
    import colorSelectorCell from '@/views/components/Table/ColorSelectorCell.vue'
    import NumberEditor from '@/views/components/Table/NumberEditor.vue'
    import DisplayAvailabilityStatus from '@/views/components/Table/DisplayAvailabilityStatus.vue'
    import DisplayPrice from '@/views/components/Table/DisplayPrice.vue'
    import DisplayPercent from '@/views/components/Table/DisplayPercent'
    import DisplayDate from '@/views/components/Table/DisplayDate'
    import DisplayTimeStamp from '@/views/components/Table/DisplayTimeStamp'
    import DisplayNumber from '@/views/components/Table/DisplayNumber.vue'

    export default {
        /*eslint-disable-next-line*/
  components: {BPagination, BDropdown, BDropdownItem, AgGridVue, CustomTooltip, DisplayPrice, agTextColumnFloatingFilter: FloatingFilter, agCellEditor: TextEditor, colorEditor: ColorEditor, priceAndPercentageEditor: PriceAndPercentageEditor, packagingEditor: PackagingEditor, emailEditor: EmailEditor, activeEditor: ActiveEditor, passwordEdit: PasswordEdit, selectEditor: SelectEditor, textAreaEditor: TextAreaEditor, agDateInput: DateEditor, btnCellRenderer:TableActions, DisplayColor, DisplayActive, DisplayStatus, DisplayDifference, colorSelectorCell, numberEditor:NumberEditor, DisplayPercent, DisplayDate, DisplayTimeStamp, DisplayAvailabilityStatus, DisplayNumber},
        props: {
            columnDefs: {
                type: Array
            },
            rowData: {
                type: Array
            },
            selected: {
                type: Array
            },
            selectValue: {
                type: String,
                default: 'id'
            },
            searchValue: {
                type: String,
                default: ''
            },
            getAllItem: {
                type: Boolean,
                default: false
            },
            findValueInArray: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                frameworkComponents: null,
                pagination: {
                    totalRows: 100,
                    perPage: 10,
                    currentPage: 1
                },
                lengths:['10', '20', '50', '100'],
                gridOptions: {
                    paginationPageSize: 10,
                    pagination: true,
                    suppressPaginationPanel: true,
                    suppressScrollOnNewData: true,
                    overlayNoRowsTemplate:
                        `<span class="p-1" style="border: 1px solid black; background: #e6e6e6; color: black;">${ this.$t('general.no_rows')  }</span>`
                },
                gridApi: null,
                context: null,
                columnApi: null,
                defaultColDef: {
                    checkboxSelection: this.isFirstColumn,
                    headerCheckboxSelectionFilteredOnly: true,
                    headerCheckboxSelection: this.isFirstColumn,
                    editable: true,
                    sortable: true,
                    resizable: true,
                    filter: false,
                    autoHeight: false,
                    flex: 1,
                    floatingFilter: true,
                    cellClass: 'cell-wrap-text',
                    minWidth: 110
                },
                rowSelection: null
            }
        },
        methods: {
            getItem(item, key) {
                const value = key.split('.').reduce((p, c) => (p && p[c]) || null, item)

                if (!value) {
                    return '/'
                }

                return  value
            },
            updateSelected(newSelection) {
                if (newSelection) {
                    if (this.getAllItem && this.selectValue === '') {
                        this.gridApi.forEachNode(node => {
                            if (newSelection.includes(node.data)) {
                                node.setSelected(true)
                            }
                        })
                    } else if (this.findValueInArray) {
                        this.gridApi.forEachNode(node => {
                            const index = newSelection.findIndex(x => x === this.getItem(node.data, this.selectValue))
                            if (index > -1) {
                                node.setSelected(true)
                            }
                        })
                    } else if (this.selectValue !== '') {
                        if (this.searchValue !== '') {
                            this.gridApi.forEachNode(node => {
                                const index = newSelection.findIndex(x => this.getItem(x, this.selectValue) === node.data[this.searchValue])
                                if (index > -1) {
                                    node.setSelected(true)
                                }
                            })
                        } else {
                            this.gridApi.forEachNode(node => {
                                const index = newSelection.findIndex(x => this.getItem(x, this.selectValue) === this.getItem(node.data, this.selectValue))
                                if (index > -1) {
                                    node.setSelected(true)
                                }
                            })
                        }
                    } else {
                        this.gridApi.forEachNode(node => {
                            const index = newSelection.findIndex(x => x === node.data.id)
                            if (index > -1) {
                                node.setSelected(true)
                            }
                        })
                    }
                }
            },
            getRowClass(params) {
                if (params.data.disabled) {
                    return 'disabled-bg-click'
                }

                return ''
            },
            selectionChanged(e) {
                const selection = []
                if (this.getAllItem) {
                    for (const select of e.api.getSelectedNodes()) {
                        selection.push(select.data)
                    }
                } else {
                    for (const select of e.api.getSelectedNodes()) {
                        if (this.selectValue !== '') {
                            selection.push(this.getItem(select.data, this.selectValue))
                        } else {
                            selection.push(select.data.id)
                        }
                    }
                }
                this.$emit('selectionChanged', selection)
            },
            onGridReady(params) {
                params.api.sizeColumnsToFit()
                this.gridApi = params.api
                if (this.selected) {
                    if (this.getAllItem && this.selectValue === '') {
                        this.gridApi.forEachNode(node => {
                            if (this.selected.includes(node.data)) {
                                node.setSelected(true)
                            }
                        })
                    } else if (this.findValueInArray) {
                        this.gridApi.forEachNode(node => {
                            const index = this.selected.findIndex(x => x === this.getItem(node.data, this.selectValue))
                            if (index > -1) {
                                node.setSelected(true)
                            }
                        })
                    } else if (this.selectValue !== '') {
                        if (this.searchValue !== '') {
                            this.gridApi.forEachNode(node => {
                                const index = this.selected.findIndex(x => this.getItem(x, this.selectValue) === node.data[this.searchValue])
                                if (index > -1) {
                                    node.setSelected(true)
                                }
                            })
                        } else {
                            this.gridApi.forEachNode(node => {
                                const index = this.selected.findIndex(x => this.getItem(x, this.selectValue) === this.getItem(node.data, this.selectValue))
                                if (index > -1) {
                                    node.setSelected(true)
                                }
                            })
                        }
                    } else {
                        this.gridApi.forEachNode(node => {
                            const index = this.selected.findIndex(x => x === node.data.id)
                            if (index > -1) {
                                node.setSelected(true)
                            }
                        })
                    }
                }
                this.$emit('gridReady')
            },
            getFilterModel() {
                return this.gridApi.getFilterModel()
            },
            setFilterModel(filterModel) {
                this.gridApi.setFilterModel(filterModel)
            },
            async refresh() {
                await this.gridApi.refreshCells({
                    force: true,
                    suppressFlash: true
                })
            },
            resizeColumnsToFit() {
                this.gridApi.sizeColumnsToFit()
            },
            setPageLength(length) {
                this.pagination.perPage = length
                this.gridApi.paginationSetPageSize(Number(length))
            },
            onCellValueChanged(params) {
                this.$emit('edit', params)
            },
            deleteRow(params) {
                this.$emit('deleteRow', params)
            },
            editRow(params, index) {
                this.$emit('editRow', params, index)
            },
            itemAdded(id) {
                this.$emit('itemAdded', id)
            },
            confirm(id) {
                this.$emit('confirm', id)
            },
            changeOrder(id) {
                this.$emit('changeOrder', id)
            },
            viewDetails(params) {
                this.$emit('viewDetails', params)
            },
            viewTemporaryFile(params) {
                this.$emit('viewTemporaryFile', params)
            },
            clone(params, index) {
                this.$emit('clone', params, index)
            },
            isFirstColumn(params) {
                const displayedColumns = params.columnApi.getAllDisplayedColumns()
                return displayedColumns[0] === params.column
            }
        },
        beforeMount() {
            this.frameworkComponents = { agDateInput: 'agDateInput', agTextCellEditor: 'agTextCellEditor', btnCellRenderer:'btnCellRenderer' }
            this.rowSelection = 'multiple'
            this.context = this

            this.pagination.totalRows = this.rowData.length
        },
        computed: {
            themeSkin() {
                return this.$store.getters['appConfig/currentSkin']
            }
        },
        watch: {
            'pagination.currentPage'(val) {
                this.gridApi.paginationGoToPage(val - 1)
            },
            'rowData'(val) {
                this.pagination.totalRows = val.length
            },
            'gridApi.paginationProxy.masterRowCount'() {
                this.pagination = {
                    totalRows: this.gridApi.paginationGetRowCount(),
                    perPage: this.gridApi.paginationGetPageSize(),
                    currentPage: this.gridApi.paginationGetCurrentPage() + 1
                }
            }
        }
    }
</script>

<style>
.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
}
.ag-floating-filter-button, .ag-header-icon.ag-header-cell-menu-button {
  display: none;
}
.ag-root-wrapper {
  /*border-radius: 0.428rem;*/
}
.ag-text-field-input {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 100% !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cell-wrap-text {
  /*white-space: normal;*/
}

.ag-theme-alpine-dark .ag-root-wrapper{
  border: none !important;
}

.ag-theme-alpine-dark .ag-header {
  background: #4b4b4b;
}
.ag-theme-alpine-dark .ag-header-cell.ag-floating-filter {
  border: none !important;
}
.ag-theme-alpine-dark .ag-row {
  background-color: #535353;
}
.ag-theme-alpine-dark .ag-row:nth-child(even) {
  background-color: #343434;
}

.ag-theme-alpine-dark .ag-checkbox-input-wrapper{
  border: none;
}
.ag-theme-alpine-dark .ag-checkbox-input-wrapper::after{
  background: #575757;
  border: none;
}
</style>