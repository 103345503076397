export default {
    methods: {
        async itemChange() {
            if (this.$router.currentRoute.name !== 'installation-wizard') {
                await this.$store.dispatch('setup/getSetupProgress')
                if (localStorage.getItem('progress') === '0') {
                    await this.$router.push('/installation-wizard')
                }
            }
            this.$emit('load')
        }
    }
}