import i18n from '@/i18n/i18n'

export const getPieceTypes = () => {
    return [{name: 'Kos', type:'piece', factor:100, defaultConverted: 100}]
}
export const getWeightTypes = () => {
    return [
        {name:'g', type:'weight', factor:1, defaultConverted: 1},
        {name:'dag', type:'weight', factor:10, defaultConverted: 1},
        {name:'kg', type:'weight', factor:1000, defaultConverted: 1}
    ]
}
export const getVolumeTypes = () => {
    return [
        {name:'mL', type:'volume', factor:1, defaultConverted: 1},
        {name:'dL', type:'volume', factor:100, defaultConverted: 1},
        {name:'L', type:'volume', factor:1000, defaultConverted: 1}
    ]
}

export const getMoneyTypes = () => {
    return [{ name: 'EUR', type: 'money', factor: 1000000, defaultConverted: 1000000}]
}

export const getAmountTypes = () => {
    return [
        {name: i18n.t('general.piece'), id: 'piece'},
        {name: i18n.t('general.volume'), id: 'volume'},
        {name: i18n.t('general.weight'), id: 'weight'},
        {name: i18n.t('general.money'), id: 'money'}
    ]
}
