<template>
    <b-modal :title="params.column.colDef.headerName" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <fa id="message" :icon="['fa','info-circle']"/>
            <b-form-textarea autofocus ref="input" v-model="value" rows="5" max-rows="6" no-resize/>
            <b-tooltip target="message">{{$t('general.max_rows')}}</b-tooltip>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="params.api.stopEditing()">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    const {nextTick} = require('@vue/composition-api')
    import { BModal, BFormTextarea, BTooltip, BButton } from 'bootstrap-vue'

    export default {
        components: {BModal, BFormTextarea, BTooltip, BButton},
        data() {
            return {
                modalActive: false,
                value: null
            }
        },
        methods: {
            getValue() {
                return this.value
            },
            isCancelBeforeStart() {
                return false
            }
        },
        mounted() {
            this.value = this.params.value
            this.modalActive = true
            nextTick(() => this.$refs.input.focus())
        }
    }

</script>