<template>
    <b-modal :title="$t('keys.predefined_discount')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('general.discount')">
                    <validation-provider #default="{ errors }" :name="$t('general.discount')" rules="required|decimalCustom:2|mustBePositive:true|min_number:0|max_value:100">
                        <b-input-group append="%">
                            <b-form-input type="number" :value="discount" v-on:input="changeDiscount" min="0" step="0.01" @keydown="(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()"/>
                        </b-input-group>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BInputGroup} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, minValue } from '@validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BInputGroup,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                modalActive: false,
                addObject: {
                    name: '',
                    discount: ''
                },
                required,
                minValue,
                discount: '',
                editing: false
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                    this.discount = this.addObject.discount / 100
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        discount: ''
                    }
                    this.discount = ''
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    const response = await this.$http.post('/api/client/v1/predefined_discounts/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        discount: ''
                    }
                    this.discount = ''
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/predefined_discounts/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        discount: ''
                    }
                    this.discount = ''
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            changeDiscount(val) {
                this.discount = val
                this.addObject.discount = Number(Number(Number(this.discount) * 100).toFixed(0))
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>