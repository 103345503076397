<template>
    <div class="ag-input-wrapper zindex-4">
        <v-select v-if="params.type === 'select'" id="table-select-filter" class="w-100" appendToBody
                  :class="[open ? 'display-open' : 'display-close']" @open="isOpen" @close="isClosed" @input="onInputBoxChanged"
                  ref="select" v-model="value" :label="label" :multiple="params.multiple ? true : false" :options="arrayOfData"
                  :reduce="params.selectValue && params.selectValue !== '' ? item => item[params.selectValue] : item => item.id" />

        <b-form-input v-else-if="params.type === 'number' || params.type === 'percentage'" class="w-100" v-model="value"
                      v-on:input="onInputBoxChanged" @keydown="keydown" type="number" min="0" step="0.01" />
        <date-picker :locale="currentLocale" :first-day-of-week="2" popover-direction="top" class="relative w-100" v-else-if="params.type === 'date'" :class="[open ? 'display-open':'display-close']" v-model="dates" is-range :masks="{input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY']}" v-on:input="onInputBoxChanged">
            <template #default="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                    <b-input-group>
                        <b-form-input class="not-disabled" :value="inputValue.start !== null ? `${inputValue.start} - ${inputValue.end}` : ''" v-on="inputEvents.start" @click="isOpen" disabled />
                        <template #append v-if="inputValue.start !== null">
                            <b-button class="not-disabled form-control" size="sm">
                                <feather-icon
                                    icon="XIcon"
                                    class="cart-item-remove cursor-pointer"
                                    @click="resetDates"
                                />
                            </b-button>
                        </template>
                    </b-input-group>
                </div>
            </template>
        </date-picker>

        <b-form-input v-else-if="params.type === 'number' || params.type === 'percentage' || params.type === 'decimal'" class="w-100" v-model="dates" v-on:input="onInputBoxChanged"
                      @keydown="keydown" type="number" min="0" step="0.01"/>

        <b-form-input v-else class="w-100" v-model="value" v-on:input="onInputBoxChanged" @keydown="keydown" type="text" />
    </div>
</template>

<script>
    import { BFormInput, BInputGroup, BButton } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    import * as Sentry from '@sentry/vue'

    import {DatePicker} from 'v-calendar'

    export default {
        components: {BFormInput, BInputGroup, BButton, vSelect, DatePicker },
        data() {
            return {
                dates: {},
                dateEnd:  this.$dayjs().add(1, 'day').toDate(),
                value: null,
                arrayOfData: [],
                open: false,
                label: 'name'
            }
        },
        computed: {
            currentLocale() {
                return this.$store.getters['user/current_locale']
            }
        },
        methods: {
            resetDates() {
                this.dates = {}
                this.value = ''
                this.onInputBoxChanged()
            },
            onInputBoxChanged() {
                if (this.dates && this.dates.start && this.dates.end) {
                    this.dates.start.setHours(0, 0, 0, 0)
                    this.dates.end.setHours(23, 59, 59, 0)
                    this.value = `${this.dates.start.toISOString()  } || ${  this.dates.end.toISOString() }`
                }

                if (this.value === '' || this.value === null) {
                    // clear the filter
                    this.params.parentFilterInstance(instance => {
                        instance.onFloatingFilterChanged(null, null)
                    })
                    return
                }

                this.params.parentFilterInstance(instance => {
                    if (this.params.type === 'number') {
                        if (Number(this.value) > 0) instance.onFloatingFilterChanged('equals', Number(Number(Number(Number(this.value) * 1000000)).toFixed(0)))
                    } else if (this.params.type === 'percentage') {
                        if (Number(this.value) > 0) instance.onFloatingFilterChanged('equals', Number(Number(Number(Number(this.value) * 100)).toFixed(0)))
                    } else if (this.params.type === 'decimal') {
                        if (Number(this.value) > 0) instance.onFloatingFilterChdanged('equals', Number(Number(Number(Number(this.value))).toFixed(0)))
                    } else if (this.params.type === 'date') {
                        instance.onFloatingFilterChanged('date', this.value)
                    } else {
                        instance.onFloatingFilterChanged('contains', this.value)
                    }
                })
            },
            onParentModelChanged(parentModel) {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    this.value = ''
                } else if (this.params.type === 'number') {
                    if (Number(this.value) > 0) this.value = Number(Number(Number(parentModel.filter) / 1000000))
                } else if (this.params.type === 'percentage') {
                    if (Number(this.value) > 0) this.value = Number(Number(Number(parentModel.filter) / 100))
                } else if (this.params.type === 'date') {
                    const filterText = parentModel.filter
                    const dates = filterText.toUpperCase().split(' || ')
                    const start = new Date(dates[0])
                    const end = new Date(dates[1])

                    this.dates = {
                        start, end
                    }
                } else {
                    this.value = parentModel.filter
                }
            },
            keydown(evt) {
                if (this.params.type === 'number' || this.params.type === 'percentage') {
                    return ['e', 'E', '+', '-', ','].includes(evt.key) && evt.preventDefault()
                } else {
                    return false && evt.preventDefault()
                }
            },
            async getValues() {
                try {
                    const response = await this.$http.get(`/api/client/v1/${this.params.values}/`)
                    this.arrayOfData = response.data
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            isOpen() {
                this.open = true
            },
            isClosed() {
                this.open = false
            }
        },
        mounted() {
            if (this.params.values) {
                if (Array.isArray(this.params.values)) this.arrayOfData = this.params.values
                else this.getValues()
            }

            if (this.params.label) {
                this.label = this.params.label
            } else {
                this.label = 'name'
            }
        },
        watch: {
            'params.filterParams.columnApi.columnModel.columnDefs'() {
                if (this.params && this.params.filterParams) {
                    const filter = this.params.filterParams.columnApi.columnModel.columnDefs.find(ele => ele.field === this.params.filterParams.colDef.field)

                    if (filter && filter.floatingFilterComponentParams && filter.floatingFilterComponentParams.values) {
                        this.arrayOfData = filter.floatingFilterComponentParams.values
                    }
                }
            }
        }
    }
</script>

<style>
#table-select-filter {
    background: white;
    border-radius: 0.357rem !important;
}

#table-select-filter .vs__dropdown-toggle {
  height: 2.714rem;
  border-radius: 0.357rem !important;
}

#table-select-filter .vs__selected {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  //max-width: calc(100% - 30%);
    width: 100%;
  padding-top: 3px;

}

#table-select-filter .display-open .vs__selected {
    display: flex;
}

#table-select-filter .display-open .vs__dropdown-menu {
    top: 40px;
}

#table-select-filter .display-close .vs__selected {
  display: block !important;
}

.display-inline-block{
    display: inline-block!important;
    width: 50%;
}

.dark-layout .not-disabled{
    background: #2a2a2a!important;
    color: white!important;
}

.dark-layout .not-disabled.btn{
    background: #2a2a2a!important;
    color: white!important;
    border: none!important;
}

.not-disabled {
    background: white!important;
    color: black!important;
}

.not-disabled.btn {
    border-top: 1px solid #d8d6de!important;
    border-right: 1px solid #d8d6de!important;
    border-bottom: 1px solid #d8d6de!important;
    border-left: none;
}

#table-select-filter .vs__selected-options {
    width: 70%;
}
</style>