<template>
    <div>
        <div v-if="value.img !== '' && value.img && value.name !== ''">
            <b-img height="14px" width="22px" :src="value.img"/>
            {{value.name }}
        </div>
        <div v-else>
            /
        </div>
    </div>
</template>

<script>
    import {getLocaleObjectsCashRegister} from '@/libs/enums/Locales'
    import {BImg} from 'bootstrap-vue'

    export default {
        components: {BImg},
        data() {
            return {
                value: null,
                array: []
            }
        },
        computed: {
            localeObjects() {
                return getLocaleObjectsCashRegister()
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                if (!params || params.value === null || params.value === undefined) {
                    return 0
                }
                return this.localeObjects.find(x => x.id === params.value) === undefined ? 0 : this.localeObjects.find(x => x.id === params.value)

            }
        },
        mounted() {
            this.array = this.params.values
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>
