<template>
    <div class="mx-2 h-100 d-flex align-items-center">
        <b-form-input class="w-100" autofocus type="color" ref="input" v-model="value" style="height: 2.114rem;"/>
    </div>
</template>
<script>
    const {nextTick} = require('@vue/composition-api')
    import { BFormInput } from 'bootstrap-vue'

    export default {
        components: {BFormInput},
        data() {
            return {
                value: null
            }
        },
        methods: {
            getValue() {
                // this simple editor doubles any value entered into the input
                return this.value
            },
            isCancelBeforeStart() {
                return false
            }
        },
        mounted() {
            this.value = this.params.value
            nextTick(() => this.$refs.input.focus())
        }
    }

</script>