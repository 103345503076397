<template>
    <div class="">
        <b-badge variant="secondary" v-if="value === CashRegisterType.Unknown">
            {{$t('config.display_device_type.no_data')}}
        </b-badge>
        <b-badge style="background: #1ab7ea;" v-else-if="value === CashRegisterType.Windows">
            {{$t('config.display_device_type.windows')}}
        </b-badge>
        <b-badge variant="success" v-else-if="value === CashRegisterType.MobileAndroid">
            {{$t('config.display_device_type.android_mobile')}}
        </b-badge>
        <b-badge  style="background: #52bb52;" v-else-if="value === CashRegisterType.DesktopAndroid">
            {{$t('config.display_device_type.android_desktop')}}
        </b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    import {CashRegisterType} from '@/libs/enums/CashRegisters'
    export default {
        computed: {
            CashRegisterType() {
                return CashRegisterType
            }
        },
        components: {BBadge},
        data() {
            return {
                value: null
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                return params.value
            }
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>

<style scoped>
</style>