import i18n from '@/i18n/i18n'

export const EventType = {
    Stopped: 1,
    Work: 2,
    Lunch: 3
}

export const EmployeeTimeEvents = [
    {name: i18n.t('employee_time.time_event_work'), value: EventType.Work},
    {name: i18n.t('employee_time.time_event_lunch'), value: EventType.Lunch},
    {name: i18n.t('employee_time.time_event_stop'), value: EventType.Stopped}
]