<template>
    <div>
        <div v-for="(item, id) in array" :key="id + item.name">
            <b-badge v-if="value === item.id" :variant="item.variant">{{item.name}}</b-badge>
        </div>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    export default {
        components: {BBadge},
        data() {
            return {
                value: null,
                array: []
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                return params.valueFormatted ? params.valueFormatted : params.value
            }
        },
        mounted() {
            this.array = this.params.values
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>

<style scoped>
</style>