<template>
    <div class="">
        <b-badge v-if="value === 0" class="" variant="secondary">{{$t('general.unknown')}}</b-badge>
        <b-badge v-else-if="value === 1" class="" variant="danger">{{$t('general.no')}}</b-badge>
        <b-badge v-else class="" variant="success">{{$t('general.yes')}}</b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    export default {
        components: {BBadge},
        data() {
            return {
                value: null
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                return params.valueFormatted ? params.valueFormatted : params.value
            }
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>

<style scoped>
</style>