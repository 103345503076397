<template>
    <div class="h-100 d-flex align-items-center">
        <b-form-input class="w-100 h-100" type="number" ref="input" v-model.number="value" min="0" step="0.01" @keydown="(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()"/>
    </div>
</template>
<script>
    const {nextTick} = require('@vue/composition-api')
    import { BFormInput } from 'bootstrap-vue'

    export default {
        components: {BFormInput},
        data() {
            return {
                value: null,
                type: ''
            }
        },
        methods: {
            getValue() {
                if (this.type === 'percentage') {
                    if (this.value > 100) this.$printError(`${this.$t('print.error.percentage')}`)
                    else return Number(Number(Number(this.value) * 100).toFixed(0))
                } else return Number(Number(Number(this.value) * 1000000).toFixed(0))
            },
            isCancelBeforeStart() {
                return false
            },
            isCancelAfterEnd() {
                // our editor will reject any value greater than 100
                if (this.type === 'percentage') {
                    if (this.value > 100) {
                        this.$printError(`${this.$t('print.error.percentage')}`)
                        return this.value > 100
                    } else return false
                } else return false
            }
        },
        mounted() {
            this.type = this.params.type
            if (this.type === 'percentage') {
                this.value = this.params.value / 100
            } else {
                this.value = this.params.value / 1000000
            }
            nextTick(() => this.$refs.input.focus())
        }
    }

</script>