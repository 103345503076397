<template>
    <div class="ag-input-wrapper">
        <b-form-input class="w-100" v-model="value" ref="input"/>
    </div>
</template>

<script>
    import { BFormInput } from 'bootstrap-vue'
    import {nextTick} from '@vue/composition-api'
    export default {
        components: {BFormInput},
        data() {
            return {
                value: null
            }
        },
        methods: {
            getValue() {
                const valid = this.validateEmail(this.value)
                if (!valid) {
                    this.$printError(`${this.$t('print.error.email')}`)
                } else {
                    return this.value
                }
            },
            isCancelBeforeStart() {
                return false
            },
            isCancelAfterEnd() {
                const valid = this.validateEmail(this.value)
                if (!valid) {
                    this.$printError(`${this.$t('print.error.email')}`)
                    return true
                }
                return false
            },
            validateEmail(email) {
                const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[A-Za-z0-9.-]/, 'gm')
                return emailRegex.test(email)
            }
        },
        mounted() {
            this.value = this.params.value
            nextTick(() => this.$refs.input.focus())
        }
    }
</script>

<style scoped>
.button {
  padding: 0.4rem;
}
</style>