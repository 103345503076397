<template>
    <div>
        <b-badge v-if="parseFloat(value) > 0" variant="danger">{{value}} €</b-badge>
        <b-badge v-else-if="parseFloat(value) === 0.00" variant="success">{{value}} €</b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    export default {
        components: {BBadge},
        data() {
            return {
                value: null
            }
        },
        methods: {
            refresh(params) {
                this.value = params.value
            }
        },
        beforeMount() {
            this.value = this.params.value
        }
    }
</script>

<style scoped>
</style>