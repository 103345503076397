<template>
    <div class="ag-input-wrapper ag-custom-component-popup custom-date-filter" role="presentation" ref="flatpickr">
        <b-form-input class="w-100" ref="eInput" data-input :placeholder="placeholder" :aria-label="ariaLabel"  :class="[themeSkin === 'dark' ? 'bg-dark' : 'bg-white']"/>
        <a class="input-button d-none d-sm-block" title="clear" data-clear :class="[themeSkin === 'dark' ? 'text-white' : '']">
            <fa :icon="['fa','times']"/>
        </a>
        <a class="d-none d-sm-block" id="calendar" title="toggle" data-toggle :class="[themeSkin === 'dark' ? 'text-white' : '']">
            <fa :icon="['fa','calendar']"/>
        </a>
    </div>
</template>

<script>
    import {BFormInput} from 'bootstrap-vue'
    const flatpickr = require('flatpickr').default
    import 'flatpickr/dist/flatpickr.css'
    export default {
        components: {BFormInput},
        data() {
            return {
                date: '',
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', locale: { firstDayOfWeek: 1}},
                placeholder: '',
                ariaLabel: ''
            }
        },
        mounted() {
            this.picker = flatpickr(this.$refs.flatpickr, {
                onChange: this.onDateChanged.bind(this),
                dateFormat: 'd.m.Y',
                wrap: true,
                locale: { firstDayOfWeek: 1}
            })

            this.eInput = this.$refs.eInput
        },
        methods: {
            onDateChanged(selectedDates) {
                this.date = selectedDates[0] || null
                this.params.onDateChanged()
            },
            getDate() {
                return this.date
            },
            setDate(date) {
                this.picker.setDate(date)
                this.date = date || null
            },

            setInputPlaceholder(placeholder) {
                this.placeholder = placeholder
            },

            setInputAriaLabel(label) {
                this.ariaLabel = label
            }
        },
        computed: {
            themeSkin() {
                return this.$store.getters['appConfig/currentSkin']
            }
        }
    }
</script>

<style>
.custom-date-filter a {
  position: absolute;
  right: 30px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.custom-date-filter #calendar {
  position: absolute;
  display: block;
  right: 10px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}
.bg-dark {
  background-color: #283046 !important;
}
</style>
<style lang="scss">
@import '@core/scss/base/plugins/forms/pickers/form-flat-pickr.scss';
</style>