<template>
    <div class="h-100 d-flex align-items-center">
        <b-form-input class="w-100 h-100" type="number" ref="input" v-model.number="value" min="0" step="0.01" @keydown="(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()"/>
    </div>
</template>
<script>
    const {nextTick} = require('@vue/composition-api')
    import { BFormInput } from 'bootstrap-vue'
    import {
        getMoneyTypes,
        getWeightTypes,
        getVolumeTypes,
        getPieceTypes
    } from '@/libs/constants/ingredients.js'


    export default {
        components: {BFormInput},
        data() {
            return {
                value: null,
                unit: '',
                startValue: null
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            }
        },
        methods: {
            getValue() {
                if (this.value === null || this.value === undefined || this.value === '') {
                    return this.startValue
                }
                return this.value
            }
        },
        mounted() {
            this.unit  = this.params.data.packaging_unit
            this.value = null
            this.startValue = this.params.data.stock

            nextTick(() => this.$refs.input.focus())
        }
    }

</script>