<template>
    <div class="h-100 d-flex align-items-center">
        <b-form-input class="w-100 h-100" type="number" v-model.number="value" ref="input" min="0" step="0.01" @keydown="(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()"/>
    </div>
</template>

<script>
    import { BFormInput } from 'bootstrap-vue'
    import {nextTick} from '@vue/composition-api'
    export default {
        components: {BFormInput},
        data() {
            return {
                value: null
            }
        },
        methods: {
            getValue() {
                return this.value
            }
        },
        mounted() {
            this.value = this.params.value
            nextTick(() => this.$refs.input.focus())
        }
    }
</script>