<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('keys.predefined_discounts.predefined_discounts')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.GeneralWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.predefined_discounts.add_predefined_discount') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="predefinedDiscounts" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>

        <AddPredefinedDiscount ref="addModal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddPredefinedDiscount from '@/views/PredefinedDiscount/AddPredefinedDiscount'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddPredefinedDiscount, BCard, BOverlay, BButton},
        computed: {
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.GeneralWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermission, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.discount_percentage'),  editable: hasPermission, field: 'discount', filter: true, filterParams: {textCustomComparator: (filter, value, filterText) => this.$amountFilter(filter, value * 10000, filterText)}, cellEditorFramework: 'priceAndPercentageEditor', cellEditorParams: {type: 'percentage'}, cellRenderer: 'DisplayPercent' }
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                predefinedDiscounts: [],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/predefined_discounts/')
                    this.predefinedDiscounts = response.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/predefined_discounts/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/predefined_discounts/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
