<template>
    <div>
        <b-badge v-if="item" :variant="item.variant" :style="[item.value === HistoryType.ReversalBill ? 'background-color: #BD4546 !important;' : '']">{{item.name}}</b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    import {HistoryType} from '@/libs/enums/IssuedBillHistoryTypes'
    export default {
        computed: {
            HistoryType() {
                return HistoryType
            }
        },
        components: {BBadge},
        data() {
            return {
                value: null,
                item: null,
                aa: 'rgb(189,69,70)',
                array: [
                    {name: this.$t('reporting.issued_bills.history.original_bill'), value: HistoryType.OriginalBill, variant: 'primary'},
                    {name: this.$t('reporting.issued_bills.history.reversal_bill'), value: HistoryType.ReversalBill, variant: 'danger'},
                    {name: this.$t('reporting.issued_bills.history.issued_invoice'), value: HistoryType.IssuedInvoice, variant: 'warning'},
                    {name: this.$t('reporting.issued_bills.history.refund_invoice'), value: HistoryType.RefundInvoice, variant: 'danger'}
                ]
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
                this.setItem()
            },
            getValueToDisplay(params) {
                return params.valueFormatted ? params.valueFormatted : params.value
            },
            setItem() {
                const item = this.array.find(x => x.value === this.value)
                if (item) this.item = item
            }
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
            this.setItem()
        }
    }
</script>

<style scoped>
</style>