<template>
    <color-select @input="changeColor"></color-select>
</template>
<script>
    import colorSelect from '@/views/components/ColorSelect.vue'

    export default {
        components: {
            colorSelect
        },
        data() {
            return {
                value: null
            }
        },
        methods: {
            changeColor(bgColor) {
                this.value = bgColor
                this.params.stopEditing()
            },
            getValue() {
                return this.value
            }
        },
        mounted() {
            this.value = this.params.value
        }
    }

</script>
<style>
</style>