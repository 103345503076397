<template>
    <div>
        {{ value }}
    </div>
</template>

<script>
    import {HistoryType} from '@/libs/enums/IssuedBillHistoryTypes'

    export default {
        data() {
            return {
                value: null,
                array: []
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
            },
            getValueToDisplay(params) {
                if (!params || params.value === null || params.value === undefined) {
                    return 0
                }
                if (params.type === HistoryType.OriginalBill || params.type === HistoryType.ReversalBill) return this.dayjs(params.timestamp).format('DD.MM.YYYY HH:mm:ss')
                return this.dayjs(params.created_at).format('DD.MM.YYYY HH:mm:ss')
            }
        },
        mounted() {
            this.array = this.params.values
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
        }
    }
</script>