<template>
    <div class="d-flex align-items-center">
        <div :id="'product_tooltip' + data.product_code">
            {{$numberRounding(data.price)}}€
        </div>
        <b-tooltip variant="light" boundary="window" :target="'product_tooltip' + data.product_code" triggers="hover" custom-class="tooltip-custom-prices" placement="right">
            <div class="text-left" style="width: 450px;">
                <div class="mb-1" style="font-weight: bold; font-size: 14px;">{{$t('config.price_in_business_units')}}:</div>
                <div class="row m-0 p-0" style="font-size: 13px;" v-for=" (item, id) in data.sale_price" :key="'products' + id + item.business_unit_id">
                    <div class="col-6 p-0" style="font-weight: bold;">{{item.business_unit_name}} </div>
                    <div class="col-6 p-0">{{ $numberRounding(item.sale_price) }}€</div>
                </div>
            </div>
        </b-tooltip>
    </div>
</template>
<script>
    import { BTooltip} from 'bootstrap-vue'
    export default {
        components:{
            BTooltip
        },
        data() {
            return {
                data: null
            }
        },
        beforeMount() {
            this.data = this.params.api.getDisplayedRowAtIndex(
                this.params.rowIndex
            ).data
        }
    }

</script>

<style>


 .tooltip-custom-prices .tooltip-inner{
        max-width: 350px;
        width: 100%;
        padding: 15px;
        border-style: solid;
        border-width: 1px;
        border-color: #D2D2CF;
        border-radius: 18px;
        box-shadow: 11px 10px 26px -7px rgba(133,131,133,1);
    }

 .tooltip-custom-prices.arrow {
     display: none;
 }
</style>